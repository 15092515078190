import React, { useState, useEffect } from "react";
import '../HealthDashboard_v2.css';
import { useLocation, useNavigate } from 'react-router-dom';

const prodURL = 'https://api-test.scan.docsun.health/api/v1';

const HealthDashboard = () => {
    const [healthData, setHealthData] = useState({ vitals: {}, vitamins: {}, wellness: {}, skin: {} });
    const [infoMessage, setInfoMessage] = useState('Click on Start Capture to start recording video');
    const [resultsGot, setResultsGot] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const getUnit = (key) => {
        const unitMap = {
            hr: 'B.P.M.',
            br: 'B.P.M.',
            spo2: '%',
            bp: 'mmHg',
            bs: 'mmol/L',
            chol: 'mg/dL',
        };
        return unitMap[key] || '%';
    };
    const getVitalPercentageValue = (value) =>{
        let adjustedValue = 5 - value;
        let percentage = adjustedValue * 20;
        return percentage + 20
    }
    
    const renderCategory = (categoryData, categoryName) => {
        const rows = Object.entries(categoryData).map(([key, value]) => {
            if (categoryName === 'vitals' && !key.includes('_score') && !key.includes('mg_dl')) {
                if (key == "chol"){
                    const scoreKey = `${key}_score`;
                    const scoreValue = getVitalPercentageValue(categoryData[scoreKey])
                    const unit = getUnit(key);
                    return (
                        <tr key={`${categoryName}-${key}`}>
                            <td>{key.toUpperCase()}</td>
                            <td>{`${categoryData["chol_mg_dl"]} ${unit}`}</td>
                            <td><HorizontalProgressBar value={scoreValue} /></td>
                        </tr>
                    );
                }else{
                    const scoreKey = `${key}_score`;
                    const scoreValue = getVitalPercentageValue(categoryData[scoreKey])
                    const unit = getUnit(key);
                    return (
                        <tr key={`${categoryName}-${key}`}>
                            <td>{key.toUpperCase()}</td>
                            <td>{`${value} ${unit}`}</td>
                            <td><HorizontalProgressBar value={scoreValue} /></td>
                        </tr>
                    );
                }
                
            } else if (!(categoryName === 'vitals') && key.includes('_score')) {
                return (
                    <tr key={`${categoryName}-${key}`}>
                        <td>{key.replace('_score', '').toUpperCase()}</td>
                        <td><HorizontalProgressBar value={value} /></td>
                    </tr>
                );
            }
        });

        return (
            <div className="category">
                <h2>{categoryName.toUpperCase()}</h2>
                <table className="health-table">
                    <thead>
                        <tr>
                            <th>{categoryName === 'vitals' ? 'Vital' : 'Indication'}</th>
                            {categoryName === 'vitals' && <th>Reading</th>}
                            <th>Progress</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        );
    };

    useEffect(() => {
        const loadLatestResult = async () => {
            const uploadID = location.state.video_id;
            const headers = {
                'Authorization': `Bearer ${location.state.token}`
            };
            try {
                let response = await fetch(`${prodURL}/user/get_all?video_id=${uploadID}`, {
                    method: 'GET',
                    headers: headers,
                });
                response = await response.json();
                if (response.message === "get all Data Successfully") {
                    setHealthData(response.results);
                    setResultsGot(true);
                    setInfoMessage('Data loaded successfully');
                } else {
                    setInfoMessage("There was an error in video. Please click on Start Capture and do a new recording.");
                    setResultsGot(false);
                }
            } catch (error) {
                console.error('Error fetching health data:', error);
                setInfoMessage("Error fetching data. Please try again.");
                setResultsGot(false);
            }
        };

        const intervalID = setInterval(loadLatestResult, 2000);
        return () => clearInterval(intervalID);
    }, []);

    return (
        <div className="health-parent">
            <div>
                <button onClick={handleBack} className="back__button">Back to Previous Page</button>
            </div>
            <div className="health-dashboard">
                <h1>Scan Report</h1>
                {renderCategory(healthData.vitals, 'vitals')}
                <hr className="hr-report" />
                {renderCategory(healthData.vitamins, 'vitamins')}
                <hr className="hr-report" />
                {renderCategory(healthData.wellness, 'wellness')}
                <hr className="hr-report" />
                {renderCategory(healthData.skin, 'skin')}
            </div>
        </div>
    );
};

const HorizontalProgressBar = ({ value }) => {
    const determineColor = (value) => {
        if (value < 25) return '#ff6347'; // tomato
        if (value < 50) return '#ffd700'; // gold
        if (value < 75) return '#adff2f'; // greenyellow
        return '#32cd32'; // limegreen
    };

    const color = determineColor(value);

    const barStyle = {
        width: `${value}%`,
        backgroundColor: color,
        height: '20px',
        borderRadius: '10px',
    };

    const containerStyle = {
        backgroundColor: '#ddd',
        borderRadius: '10px',
        padding: '2px',
    };

    const limitTextStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '12px',
        color: '#666',
    };

    return (
        <div className="horizontal-progress-bar">
            <div style={containerStyle}>
                <div className="progress" style={barStyle}></div>
            </div>

            {/* Text ranges displayed below */}
            <div className="limit-text" style={limitTextStyle}>
                <span>Subpar</span>
                <span>Average</span>
                <span>Good</span>
                <span>Excellent</span>
            </div>
        </div>
    );
};

export default HealthDashboard;
