import React, { createContext,useContext, useEffect, useRef, useState } from 'react'

var authContext = createContext();
let prodURL = "https://api-test.scan.docsun.health/api/v1";

export function useAuth() {
    const login = async (uname,pass)=>{
        let data = {
            'email':uname,
            'password':pass
        };
        let response = await fetch(`${prodURL}/login`,{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify(data)
        })
        response = await response.json();
        if (response.message === "Login successful"){
            localStorage.setItem("status",true)
            // localStorage.setItem("licence",response.licencekey);
            // localStorage.setItem("token",response.accessToken);
            return {
                status:true,
                message:response.message,
                accessToken:response.result.accessToken,
                license_key:response.result.license_key,
                user_id:response.result.user_id
            }
        }else{
            localStorage.setItem("status",false)
            return {
                status:false,
                message:response.message,
                accessToken:'',
                license_key:'',
                user_id:''
            }
        }
    }
  return {
    login,
    };
}
export function AuthProvider({children}){
    var auth = useAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>
}
export default function AuthConsumer(){
    return useContext(authContext);
}