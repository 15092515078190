import './App.css';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";
import Scan from './components/Scan';
import SignIn from './components/SignIn';
import HealthDashboard from './components/HealthDashboard_v3';
function RequireAuth({children}){
  return localStorage.getItem("status") == 'true' ? children : <Navigate to="/" replace/>;
}
function App() {
  return (
    <div className='app-main'>
    <Router>
      <Routes>
        <Route path="/scan" element={
          <RequireAuth>
            <Scan />
          </RequireAuth>
        } />
        <Route path="/result" element={
          <RequireAuth>
            <HealthDashboard />
          </RequireAuth>
        } />
        <Route path="/" element={<SignIn />} />
      </Routes>
      </Router>
    </div>
  );
}
export default App;
