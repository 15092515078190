import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import '../SignIn.css'
import logo from '../imgs/logo.png'
import { useAuth } from './useAuth';
function SignIn() {
    const navigate = useNavigate();
    const [errorMessage,setErrorMessage]=useState('');
    var {login} = useAuth();

    const handleSignIn = (event)=>{
        event.preventDefault(); // prevent page reload
        var {uname,pass} = document.forms[0];
        // console.log(uname.value,pass.value);
        login(uname.value,pass.value).then((res)=>{
            setErrorMessage(res.message);
            if (res.status){
                // localStorage.setItem("licence",res.licencekey);
                // localStorage.setItem("token",res.accessToken);
                navigate('/scan',{
                  state:{
                    token:res.accessToken,
                    licence:res.license_key,
                  }
                });
            }
        }
        )
    }
  return (
    <div className="container">
      <div className="forms-container">
        <div className="signin-signup">
          <form action="#" className="sign-in-form" onSubmit={handleSignIn}>
            <h2 className="title">Sign in</h2>
            <div className="input-field">
              <i className="fas fa-user"></i>
              <input type="text" placeholder="Username" name='uname' />
            </div>
            <div className="input-field">
              <i className="fas fa-lock"></i>
              <input type="password" name='pass' placeholder="Password" />
            </div>
            <div className='error__value'>{errorMessage}</div>
            <input type="submit" value="Login" className="btn solid" />
            <div className='error__value'>Please contact a Docsun Sales Representative for any query or renewal !</div>
          </form>
        </div>
      </div>
      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <h3>Docsun AI SDK</h3>
            <p>
            Non-Contact Vital Health Solutions For All
            </p>
          </div>
        <img src={logo} alt="Docsun" className="image"/>
        </div>
      </div>
    </div>
  )
}

export default SignIn