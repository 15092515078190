import React, { useCallback, useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";
import '../WebcamVideo.css';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../imgs/logo.png';
import inst_video from '../video/inst_video.webm';
import { FaceDetection } from '@mediapipe/face_detection';
import { Camera } from '@mediapipe/camera_utils';

const timerValueInit = 10;
const prodURL = 'https://api-test.scan.docsun.health/api/v1';
const defaultMessage = 'Click on Start Capture to start recording video';
let uploadID = '';

export default function WebcamVideo() {
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [infoMessage, setInfoMessage] = useState(defaultMessage);
    const [timerValue, setTimerValue] = useState(timerValueInit);
    const [uploadStatus, setUploadStatus] = useState(false);
    const [hideVideo, setHideVideo] = useState(false);
    const [hideSkip, setHideSkip] = useState(false);
    const vidRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    const canvasRef = useRef();
    
    useEffect(() => {
      const faceDetection = new FaceDetection({locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`;
      }});
      faceDetection.setOptions({
        model: 'short',
        minDetectionConfidence: 0.5
      });
      faceDetection.onResults(onResults);
    
      if (typeof webcamRef.current !== "undefined" && webcamRef.current !== null) {
        const camera = new Camera(webcamRef.current.video, {
          onFrame: async () => {
            await faceDetection.send({image: webcamRef.current.video});
          },
          width: 640,
          height: 480
        });
        camera.start();
      }
    }, []);
    
    const onResults = (results) => {
      const canvasElement = canvasRef.current;
      const canvasCtx = canvasElement.getContext('2d');
      canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
      canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);
  
      if (results.detections.length > 0) {
          let detection_score=results.detections[0].V[0].ga;
          results.detections.forEach(face => {
              const { xCenter, yCenter, width, height } = face.boundingBox;
              // Calculate the top left corner from the center point
              const xMin = xCenter - (width / 2);
              const yMin = yCenter - (height / 2);
  
              canvasCtx.beginPath();
              canvasCtx.rect(
                  xMin * canvasElement.width,      // Scale xMin to canvas width
                  yMin * canvasElement.height,     // Scale yMin to canvas height
                  width * canvasElement.width,     // Scale width to canvas width
                  height * canvasElement.height    // Scale height to canvas height
              );
              canvasCtx.lineWidth = 4;
              if (detection_score >=0.85){
                canvasCtx.strokeStyle = 'green';
              }
              else{
                canvasCtx.strokeStyle = 'red';
              }
              canvasCtx.stroke();
          });
      }
  };

  const handleStopVideo = () => {
        vidRef.current.pause();
    };

    const handleDataAvailable = useCallback(({ data }) => {
        if (data.size > 0) {
            setRecordedChunks(prev => prev.concat(data));
        }
    }, []);

    const handleStartCaptureClick = useCallback(() => {
        setCapturing(true);
        let options;
        if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
            options = { mimeType: 'video/webm; codecs=vp9' };
        } else if (MediaRecorder.isTypeSupported('video/webm')) {
            options = { mimeType: 'video/webm' };
        } else if (MediaRecorder.isTypeSupported('video/mp4')) {
            options = { mimeType: 'video/mp4', videoBitsPerSecond: 2500000 };
        } else {
            console.error("No suitable mimetype found for this device.");
            return;
        }

        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, options);
        mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
        mediaRecorderRef.current.start(1000);

        setInfoMessage('');
        const intervalID = setInterval(() => {
            setTimerValue(prevValue => prevValue > 0 ? prevValue - 1 : 0);
        }, 1000);

        setTimeout(() => {
            mediaRecorderRef.current.stop();
            setCapturing(false);
            setInfoMessage('Video recording completed. Please click on Upload Video to get scan results.');
            clearInterval(intervalID);
            setTimerValue(timerValueInit);
        }, 10000);
    }, [setCapturing, handleDataAvailable]);

    const handleDownload = useCallback(async () => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, { type: "video/mp4" });
            const formData = new FormData();
            formData.append("file", blob, `video-apidemo-upload.mp4`);
            formData.append("license_key", location.state.licence);

            const headers = {
                'Authorization': `Bearer ${location.state.token}`
            };

            try {
                let response = await fetch(`${prodURL}/user/upload-video`, {
                    method: 'POST',
                    headers: headers,
                    body: formData
                });
                response = await response.json();
                console.log('Video upload Response : ', response);
                if (response.message === "Uploaded Video Successfully") {
                    uploadID = response.video_id;
                    setUploadStatus(true);
                    setInfoMessage('Loading scan results.');
                } else {
                    setUploadStatus(false);
                    setInfoMessage('Failed to upload video. Please try again.');
                }
            } catch (error) {
                console.error('Error uploading video:', error);
                setUploadStatus(false);
                setInfoMessage('Error uploading video. Please try again.');
            }
            setRecordedChunks([]);
        }
    }, [recordedChunks, location.state.token, location.state.licence]);

    const videoConstraints = {
        facingMode: "user",
    };

    return (
        <div className="body">
            <div className="webcam__container">
                <div className='skip__container'>
                    <button id='skip__btn' className={hideSkip ? "" : "skip__btn"} onClick={() => { setHideVideo(true); setHideSkip(true); handleStopVideo(); }} hidden={hideSkip}>Skip Video</button>
                </div>
                <div className="inst__container">
                    <video ref={vidRef} loop muted autoPlay controls hidden={hideVideo} className="inst__video">
                        <source src={inst_video} type="video/webm" />
                    </video>
                </div>
                
                <Webcam
                  className="webcam__video"
                  audio={false}
                  mirrored={true}
                  ref={webcamRef}
                  videoConstraints={videoConstraints}
                  width="640"
                  height="480"
                  style={{visibility: "hidden"}}
                />
                <canvas ref={canvasRef} className="face__overlay" style={{position: 'absolute',width:'640px', height:"480px"}} />
                <div className="timer__container">
                    <input className="timer__value" readOnly value={timerValue}></input>
                </div>
                <div className="button__container">
                    {!capturing && (
                        <button onClick={handleStartCaptureClick} className="start__button">Start Capture</button>
                    )}
                    {recordedChunks.length > 0 && !capturing && (
                        <button onClick={handleDownload} className="upload__button">Upload Video</button>
                    )}
                </div>
            </div>
            <div className="info__container">
                <p className="info__message"><span className="info__value">{infoMessage}</span></p>
            </div>
            {uploadStatus && (
                <div className="result__container">
                    <button onClick={() => navigate('/result', {
                        state: {
                            token: location.state.token,
                            licence: location.state.licence,
                            video_id: uploadID
                        }
                    })} className="result__button">Check Results</button>
                </div>
            )}
        </div>
    );
}
